<template>
  <Page :hasBg="false">
    <!-- 导航栏 -->
    <NavBar title="资金明细">
      <template #right>
        <!-- 状态筛选按钮 -->
        <Popover v-model="showPopover" trigger="click" placement="bottom-end">
          <ul class="select-list">
            <li
              :class="{ selected: item.value === selected.value }"
              v-for="item in actions"
              :key="item.value"
              @click="handleSelect(item)"
            >
              {{ item.text }}
            </li>
          </ul>
          <template #reference>
            <div class="d-flex">
              <p class="text_primary fw-600">{{ selected.text }}</p>
              <img class="mt-2 ml-4" style="height:0.14rem;" src="@assets/icon_xl.png" />
            </div>
          </template>
        </Popover>
      </template>
    </NavBar>

    <!-- 列表 -->
    <div class="mb-16 content" v-for="(card, index) in list" :key="card.time">
      <div class="select-time d-flex align-center">
        <p class="text_primary" @click="handleChangeShow(index)">
          {{ card.time }}
        </p>
        <Icon :name="card.show ? 'xiala-x' : 'xiala-1'"></Icon>
      </div>
      <div class="mt-2 total text_muted d-flex" :class="{ 'mb-12': !card.show }">
        <p v-if="selected.value === 0 || selected.value === 2" class="mb-4 mr-16">
          支出： {{ useFormatMoney(card.expendTotal / 100) }}
        </p>
        <p v-if="selected.value === 0 || selected.value === 1" class="mb-4">
          收入： {{ useFormatMoney(card.incomeTotal / 100) }}
        </p>
      </div>

      <!-- 列表 -->
      <ul class="record-list" v-if="card.show">
        <li
          class="record-list-card d-flex"
          @click="handleRouterToDetailInfo(item.accountFundFlowId)"
          v-for="item in card.accountFundFlowMonthVOS || []"
          :key="item.accountFundFlowId"
        >
          <Icon class="icon" :name="item.incomeType === 1 ? 'in' : 'out'"></Icon>
          <div class="d-flex justify-space-between flex-grow-1">
            <div class="ml-8 center">
              <div class="d-flex align-center">
                <!-- <p>{{ item.incomeType === 1 ? '收入' : '提现' }}</p>
                <P v-if="item.incomeType === 2" class="ml-8 text_muted font-12">
                  <span>{{ item.bankName }}</span>
                  <span v-if="item.bankCardNumber">（{{ item.bankCardNumber.slice(-4) }}）</span>
                </P> -->
                <span class="mr-8" v-if="item.incomeType === 1">收入</span>
                <span class="mr-8" v-if="item.incomeType === 2">
                  <span v-if="item.type === 8">收入退款</span>
                  <span v-else>提现</span>
                </span>

                <span class="text_muted" v-if="item.incomeType === 1">
                  {{ `${item.confirmShareDriverName || ''} ${item.confirmSharePhone || ''}` }}
                </span>
                <span v-if="item.incomeType === 2">
                  <span v-if="item.type === 8"></span>
                  <span v-else>
                    {{ item.bankName || '' }}
                    {{ item.bankCardNumber && `(${item.bankCardNumber.slice(-4)})` }}
                  </span>
                </span>
              </div>
              <p class="mt-12 font-12 text_muted">
                {{ formatToDateTime(item.tradeTime, 'MM月dd日 hh:mm') }}
              </p>
            </div>
            <div class="text-right right ">
              <p>{{ item.incomeType === 1 ? '+' : '-' }}{{ ((item.tradeAmount || 0) / 100).toFixed(2) }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- 缺省页 -->
    <!-- <Empty v-if="!list.length" imgWidth="0.84rem" imgHeight="0.78rem" :image="emptyImg" description="暂无资金明细" /> -->
    <div v-if="inited && !list.length" class="flex flex-col items-center justify-center h-full">
      <div class="flex flex-col items-center p-32">
        <img
          class="w-134 h-134"
          src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/chatu_wuzijinmingxi%403x.png"
        />
        <span class="text-muted text-16">无资金明细</span>
      </div>
    </div>
  </Page>
</template>
<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api';
import { useRouter } from '@/utils/compsitionHack';
import NavBar from '@/components/NavBar/index';
import { Button, Popover } from 'vant';
import useFormatMoney from '@/hook/useFormatMoney';
import Empty from '@/components/Empty/index';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import { apiSearchAccountFundFlowMap } from '@/api/balance';
import { useUserStore } from '@/store/user';
import { PageEnum } from '@/enums/pageEnum';
import { Toast } from 'vant';

export default defineComponent({
  components: {
    NavBar,
    Button,
    Popover,
    Empty
  },
  setup() {
    const inited = ref(false);

    const router = useRouter();
    // 改变页面背景颜色
    onMounted(async () => {
      handleGetData('');
    });
    // 缺省页图片
    const emptyImg = require('@/assets/ydkb.png');
    // 列表
    const list = ref([]);
    // 选中的状态
    const selected = ref({ text: '全部', value: 0 });
    // 状态选择栏
    const showPopover = ref(false);
    const actions = ref([
      { text: '全部', value: '' },
      { text: '收入', value: 1 },
      { text: '支出', value: 2 }
    ]);
    const userStore = useUserStore();

    // 获取数据
    const handleGetData = async type => {
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: '加载中...'
      });
      try {
        const res = await apiSearchAccountFundFlowMap({
          driverIdCard: userStore?.idCard,
          incomeType: type
        });
        if (res.data) {
          inited.value = true;
          res.data.forEach(item => {
            item.show = true;
          });
          list.value = res.data;
        }
      } finally {
        Toast.clear();
      }
    };

    // 切换状态
    const handleSelect = action => {
      showPopover.value = false;
      selected.value = action;
      handleGetData(action.value);
    };

    // 展开收起
    const handleChangeShow = index => {
      list.value[index].show = !list.value[index].show;
    };

    const handleRouterToDetailInfo = id => {
      router.push({ path: PageEnum.BALANCE_DETAILINFO, query: { id } });
    };

    return {
      list,
      useFormatMoney,
      formatToDateTime,
      showPopover,
      actions,
      handleSelect,
      selected,
      handleRouterToDetailInfo,
      handleChangeShow,
      emptyImg,
      inited
    };
  }
});
</script>
<style lang="less" scoped>
.content {
  background: #fff;
  padding: 0.16rem 0.16rem 0;
  border-radius: 0.04rem;
  overflow: hidden;
  .record-list {
    &-card {
      margin-top: 0.1rem;
      padding: 0.16rem 0;
      border-bottom: 1px solid #f5f5f5;

      &:nth-last-child(1) {
        border: none;
      }
    }
  }
}
.select-list {
  border: 1px solid #f5f5f5;
  li {
    height: 0.48rem;
    width: 2.08rem;
    text-align: center;
    font-size: 0.16rem;
    font-weight: 600;
    line-height: 0.48rem;
    border-bottom: 1px solid #f5f5f5;
    &:nth-last-child(1) {
      border: none;
    }
  }
  .selected {
    color: #0076ff;
  }
}
</style>
